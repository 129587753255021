<script setup lang="ts">
import { joinPath, resolveNodeId, useObjectVModel, useRepeater } from '@manager'
import type {
  IManagerObjectEmits,
  IManagerObjectProps,
  IManagerObjectSlots,
} from './types'

const props = withDefaults(defineProps<IManagerObjectProps>(), {
  parentValue: () => ({}),
  parentPath: '',
})
const emit = defineEmits<IManagerObjectEmits>()
defineSlots<IManagerObjectSlots>()

const path = computed(() => joinPath(props.parentPath, props.node.name))
const joinDataPath = (index: number) => joinPath(path.value, `data[${index}]`)

const [parentValue, dataModelValue] = useObjectVModel(
  props,
  'parentValue',
  emit,
  true,
)

const { items, filteredItems, add, removeById } = useRepeater(dataModelValue)

const showMenu = (idx: number) =>
  props.node.canAddCopies && idx === 0 ? true : undefined
const addNewSectionText = computed(() => {
  return props.node.canAddCopies
    ? `Add new ${props.node.label?.toLowerCase()}`
    : ''
})
</script>

<template>
  <!-- Object -->
  <slot name="body" v-bind="{ items, filteredItems, add, remove: removeById }">
    <ManagerSharedObjectDivider :data-path="path">
      <template v-for="(item, index) in filteredItems" :key="item.__id">
        <slot v-bind="{ items, filteredItems, item, add, remove: removeById }">
          <ManagerSharedGroupGrid>
            <template
              v-for="(childNode, childNodeIndex) in node.nodes"
              :key="resolveNodeId(childNode)"
            >
              <ManagerNode
                v-model:parent-value="filteredItems[index]"
                :node="childNode"
                :parent-path="joinDataPath(index)"
                :parent-node="node"
                :show-menu="showMenu(childNodeIndex)"
                @remove="removeById(item.__id)"
              />
            </template>
          </ManagerSharedGroupGrid>
        </slot>
      </template>
    </ManagerSharedObjectDivider>
  </slot>

  <slot
    name="footer"
    v-bind="{ items, filteredItems, add, remove: removeById }"
  >
    <ButtonAddNewSection
      v-if="node.canAddCopies"
      :title="addNewSectionText"
      icon="plus"
      size="sm"
      class="col-span-full"
      @click="add()"
    />
  </slot>
</template>
